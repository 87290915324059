import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import mermaid from 'mermaid';
import { ButtonClickedFlowchart, ButtonNodeFlowchart, NodeFlowchart } from '@induxtry/library/utils/models/common.interface';

@Component({
  selector: 'second-flowchart-spic',
  templateUrl: './second-flowchart.component.html',
  styleUrls: ['./second-flowchart.component.scss']
})
export class SecondFlowchartComponentSpic implements OnInit, OnChanges, AfterViewChecked {
  @Input() nodes: NodeFlowchart[];
  @Output() buttonClicked: EventEmitter<ButtonClickedFlowchart> = new EventEmitter<ButtonClickedFlowchart>();

  flowChartSecond: any;
  stringFlowChartSecond: any = '';
  listernerEventsSecond: boolean;
  hasButtonsSecond: boolean;
  mermaidInstanceSecond: any;
  defaultValueSecond = true;
  loadingFlowchartsecond: boolean

  constructor(
    private readonly renderer: Renderer2,
    private readonly zone: NgZone,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.mermaidInstanceSecond = mermaid.initialize({ startOnLoad: false });

  }

  ngOnChanges(change): void {
    if (change?.nodes) {
      this.hasButtonsSecond = (this.nodes.filter(res => res.buttons).length > 0);
      this.createFlowchartSecond(change.nodes.currentValue, !change.nodes.firstChange);
      if (!change.nodes.firstChange) {
        this.listernerEventsSecond = true;
      }
    }
  }

  ngAfterViewChecked() {
    if (this.hasButtonsSecond) {
      this.zone.runOutsideAngular(() => {
        if (!this.listernerEventsSecond) {
          this.addEventListenersFlowChartSecond();
        }
      });
    }
  }

  changeColorArrowsSecond() {

    this.nodes.forEach(item => {
     // console.log(item)
      

      document.querySelectorAll(`.LS-${item.key}`).forEach((arw: any) => {
        arw.style.stroke = `${item?.color ? item.color : '#000000'}`;
      })
      document.querySelectorAll(`.bg-color-arrow-${item.key}`).forEach((arw: any) => {
        arw.style.backgroundColor = `${item?.color ? item.color : '#000000'}`;
      })
 
        if(item.key !== "0" && !item.color){

        const parentColor = item.parent ? 
        this.nodes.find((o=>{return o.key == item.parent[0]})).color?.replace('#','') : '';
    
        const nodeLayout = document.querySelector(`.flowchart-label[data-id="${item.key}"] rect`);
        if (nodeLayout) {
          nodeLayout.classList.add(`detach-element-flowchart-${parentColor}`);
        }

      }
    })
  }

  addEventListenersFlowChartSecond() {
    if (this.nodes.length > 0) {
      this.changeColorArrowsSecond();
    }
    const buttonsFlowchart = this.elementRef.nativeElement.querySelectorAll('.js-event-click-btn-second-flowchart');
    if (buttonsFlowchart.length > 0) {
      buttonsFlowchart.forEach(item => {
        const nodeBtn = this.nodes.find(res => res.key === item.getAttribute('data-id'));
        this.renderer.listen(item, 'click', () => this.onClickButtonFlowchartSecond(item.getAttribute('data-action'), nodeBtn));
      })
      this.listernerEventsSecond = true;
    }
  }

  createButtonsFlowchartSecond(buttons: ButtonNodeFlowchart[], key: string): string {
    let btns = '';
    buttons.forEach(btn => {
      btns += `
        <button
          class="js-event-click-btn-second-flowchart ${btn.class ? btn.class : 'button-flowchart'}"
          data-id="${key}"
          data-action="${btn.action}"
        >
          ${btn.title}
        </button>
      `;
    });
    return btns;
  }

  insertHtmlFlowchartSecond(titleSecond: string, nodeSecond: NodeFlowchart): string {
    let buttons = '';
    if (nodeSecond.buttons) {
      buttons = this.createButtonsFlowchartSecond(nodeSecond.buttons, nodeSecond.key);
    }
    const parentColor = nodeSecond.parent ? 
      this.nodes.find((o=>{return o.key == nodeSecond.parent[0]})).color?.replace('#','') : '';

    return `
      <div
        class=" ${nodeSecond?.key !== '0' && !nodeSecond.color ? ('detach-father-flowchart-'+ parentColor || '') : ''} node-second-flow-chart"
      >
        <p>${titleSecond}</p>
        ${buttons ? `<div>${buttons}</div>` : ''}
      </div>
    `;
  }

  onClickButtonFlowchartSecond(actionSecond: string, nodeSecond: NodeFlowchart): void {
    this.buttonClicked.emit({
      action: actionSecond,
      node: nodeSecond
    });
  }

  mountObjectFlowchartSecond(nodesSecond: NodeFlowchart[]): string[] {
    let objFlowchart = [
      'flowchart LR'
    ];
    nodesSecond.forEach(node => {
      if (node.parent) {
        const parents = nodesSecond.filter(res => node.parent.indexOf(res.key) !== -1);
        parents.forEach(parent => {
          objFlowchart.push(`
          ${parent.key}(${this.insertHtmlFlowchartSecond(parent.title, parent)}) === ${node.key}(${this.insertHtmlFlowchartSecond(node.title, node)})
          `)
        });
      } else {
        objFlowchart.push(`
          ${node.key}(${this.insertHtmlFlowchartSecond(node.title, node)})
        `)
      }
    })
    return objFlowchart;
  }

  createFlowchartSecond(nodesSecond?: NodeFlowchart[], refreshSecond?: boolean) {
    this.loadingFlowchartsecond = true;
    if (refreshSecond) {
      this.elementRef.nativeElement.querySelector('.mermaid').innerHTML = '';
      this.defaultValueSecond = false;
      setTimeout(() => {
        this.defaultValueSecond = true;
      }, 300);
    }
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.flowChartSecond = this.mountObjectFlowchartSecond(nodesSecond || this.nodes);
        this.stringFlowChartSecond = this.flowChartSecond.join("\n");
        this.elementRef.nativeElement.querySelector('.mermaid').innerHTML = this.stringFlowChartSecond;
        mermaid.init();
        this.listernerEventsSecond = false;
      }, 500);
    });
    setTimeout(() => {
      this.loadingFlowchartsecond = false;
    }, 550);
  }
}
